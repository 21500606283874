import { Link } from "gatsby";
import React from "react";
import Button from "../atoms/button";
import Wand from '../../assets/images/svg/wand_2.svg';

import {
    buttonWand,
    buttonLink,
    buttonNew,
    buttonIcon,
} from './button-wand.module.scss';


interface IButtonWandProps {
    className?: '',
    text?: string
}

const ButtonWand: React.FC<IButtonWandProps> = ({
    className = '',
    text = ''
}) => {
    return (
        <div className={`${className} ${buttonWand}`}>
            <Link className={buttonLink} to="/wystylizuj-mnie">
                <Button className={buttonNew} color="primary" type="filled">
                    <div className={buttonIcon}><Wand></Wand></div>
                    <>{text}</>
                </Button>
            </Link>
        </div>
    );
};


export default ButtonWand;